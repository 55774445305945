<template>
  <div class="page5">
    <Coldiv direction="left">
      <div style="margin-top: 3.23vh;">
        <Subtitle text="人员分类"></Subtitle>
        <div class="classifyTagRow" style="margin-top: 1.85vh">
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[0].typeName:''"
            :value="classify.length?classify[0].total:0"
            :selected="selectedClassify==0?true:false"
            @click="()=>{selectClassify(0)}"
          ></Short-button>
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[3].typeName:''"
            :value="classify.length?classify[3].total:0"
            :selected="selectedClassify==3?true:false"
            @click="()=>{selectClassify(3)}"
          ></Short-button>
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[4].typeName:''"
            :value="classify.length?classify[4].total:0"
            :selected="selectedClassify==4?true:false"
            @click="()=>{selectClassify(4)}"
          ></Short-button>
        </div>
        <div class="classifyTagRow" style="margin-top: 1.85vh">
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[6].typeName:''"
            :value="classify.length?classify[6].total:0"
            :selected="selectedClassify==6?true:false"
            @click="()=>{selectClassify(6)}"
          ></Short-button>
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[7].typeName:''"
            :value="classify.length?classify[7].total:0"
            :selected="selectedClassify==7?true:false"
            @click="()=>{selectClassify(7)}"
          ></Short-button>
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[8].typeName:''"
            :value="classify.length?classify[8].total:0"
            :selected="selectedClassify==8?true:false"
            @click="()=>{selectClassify(8)}"
          ></Short-button>
        </div>
        <div class="classifyTagRow" style="margin-top: 1.85vh">
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[9].typeName:''"
            :value="classify.length?classify[9].total:0"
            :selected="selectedClassify==9?true:false"
            @click="()=>{selectClassify(9)}"
          ></Short-button>
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[10].typeName:''"
            :value="classify.length?classify[10].total:0"
            :selected="selectedClassify==10?true:false"
            @click="()=>{selectClassify(10)}"
          ></Short-button>
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[13].typeName:''"
            :value="classify.length?classify[13].total:0"
            :selected="selectedClassify==13?true:false"
            @click="()=>{selectClassify(13)}"
          ></Short-button>
        </div>
        <div class="classifyTagRow" style="margin-top: 1.85vh">
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[14].typeName:''"
            :value="classify.length?classify[14].total:0"
            :selected="selectedClassify==14?true:false"
            @click="()=>{selectClassify(14)}"
          ></Short-button>
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[15].typeName:''"
            :value="classify.length?classify[15].total:0"
            :selected="selectedClassify==15?true:false"
            @click="()=>{selectClassify(15)}"
          ></Short-button>
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[16].typeName:''"
            :value="classify.length?classify[16].total:0"
            :selected="selectedClassify==16?true:false"
            @click="()=>{selectClassify(16)}"
          ></Short-button>
        </div>
        <!--<div class="classifyTagRow" style="margin-top: 1.85vh">
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[12].typeName:''"
            :value="classify.length?classify[12].total:0"
            :selected="selectedClassify==12?true:false"
            @click="()=>{selectClassify(12)}"
          ></Short-button>
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[13].typeName:''"
            :value="classify.length?classify[13].total:0"
            :selected="selectedClassify==13?true:false"
            @click="()=>{selectClassify(13)}"
          ></Short-button>
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[14].typeName:''"
            :value="classify.length?classify[14].total:0"
            :selected="selectedClassify==14?true:false"
            @click="()=>{selectClassify(14)}"
          ></Short-button>
        </div>
        <div class="classifyTagRow" style="margin-top: 1.85vh">
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[15].typeName:''"
            :value="classify.length?classify[15].total:0"
            :selected="selectedClassify==15?true:false"
            @click="()=>{selectClassify(15)}"
          ></Short-button>
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[16].typeName:''"
            :value="classify.length?classify[16].total:0"
            :selected="selectedClassify==16?true:false"
            @click="()=>{selectClassify(16)}"
          ></Short-button>
          <Short-button
            class="shortButton"
            :itemName="classify.length?classify[17].typeName:''"
            :value="classify.length?classify[17].total:0"
            :selected="selectedClassify==17?true:false"
            @click="()=>{selectClassify(17)}"
          ></Short-button>
        </div>-->
        <scrollview class="classifyList" :sKey="sKey1">
          <person-profile-2
            v-for="(item, index) in classify.length?classify[selectedClassify].list:[]"
            :key="index"
            :dataInput="item"
          ></person-profile-2>
        </scrollview>
      </div>
    </Coldiv>
    <Coldiv direction="right">
      <div style="margin-top: 3.7vh">
        <Subtitle text="关爱预警"></Subtitle>
        <div class="love-box">
          <img class="love-img" src="@/assets/page5/返贫致贫公告.svg" alt />
          <div class="love-content">
            <div class="love-content-item">
              <div class="love-content-top">
                <img src="@/assets/page5/脱贫户.svg" alt />
                <span>脱贫户</span>
              </div>
              <active-number class="love-content-number" :number="householdNumber"></active-number>
            </div>
            <div class="love-content-item">
              <div class="love-content-top">
                <img src="@/assets/page5/低保户.svg" alt />
                <span>{{inspection?'监测户':'低保户'}}</span>
              </div>
              <active-number class="love-content-number" :number="povertyHouseNumber"></active-number>
            </div>
            <div class="love-content-item">
              <div class="love-content-top">
                <img src="@/assets/page5/特困户.svg" alt />
                <span>{{inspection?'监测户人数':'特困户'}}</span>
              </div>
              <active-number class="love-content-number" :number="specialHouseNumber"></active-number>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 3.23vh">
        <Subtitle text="返贫提示"></Subtitle>
        <div class="serviceSystemBox">
          <div class="serviceButtonBox">
            <Long-button
              class="longButton"
              :itemName="leaders.length?leaders[0].typeName:''"
              :value="leaders.length?leaders[0].total:0"
              :selected="selectedLeaders==0?true:false"
              @click="()=>{selectLeader(0)}"
            ></Long-button>
            <Long-button
              class="longButton"
              :itemName="leaders.length?leaders[1].typeName:''"
              :value="leaders.length?leaders[1].total:0"
              :selected="selectedLeaders==1?true:false"
              @click="()=>{selectLeader(1)}"
            ></Long-button>
            <Long-button
              class="longButton"
              :itemName="leaders.length?leaders[2].typeName:''"
              :value="leaders.length?leaders[2].total:0"
              :selected="selectedLeaders==2?true:false"
              @click="()=>{selectLeader(2)}"
            ></Long-button>
            <Long-button
              class="longButton"
              :itemName="leaders.length?leaders[3].typeName:''"
              :value="leaders.length?leaders[3].total:0"
              :selected="selectedLeaders==3?true:false"
              @click="()=>{selectLeader(3)}"
            ></Long-button>
            <Long-button
              class="longButton"
              :itemName="leaders.length?leaders[4].typeName:''"
              :value="leaders.length?leaders[4].total:0"
              :selected="selectedLeaders==4?true:false"
              @click="()=>{selectLeader(4)}"
            ></Long-button>
            <Long-button
              class="longButton"
              :itemName="leaders.length?leaders[5].typeName:''"
              :value="leaders.length?leaders[5].total:0"
              :selected="selectedLeaders==5?true:false"
              @click="()=>{selectLeader(5)}"
            ></Long-button>
            <Long-button
              class="longButton"
              :itemName="leaders.length?leaders[6].typeName:''"
              :value="leaders.length?leaders[6].total:0"
              :selected="selectedLeaders==6?true:false"
              @click="()=>{selectLeader(6)}"
            ></Long-button>
            <Long-button
              class="longButton"
              :itemName="leaders.length?leaders[7].typeName:''"
              :value="leaders.length?leaders[7].total:0"
              :selected="selectedLeaders==7?true:false"
              @click="()=>{selectLeader(7)}"
            ></Long-button>
            <Long-button
              class="longButton"
              :itemName="leaders.length?leaders[8].typeName:''"
              :value="leaders.length?leaders[8].total:0"
              :selected="selectedLeaders==8?true:false"
              @click="()=>{selectLeader(8)}"
            ></Long-button>
          </div>
          <scrollview class="serviceList" :sKey="sKey2">
            <person-profile-3
              v-for="(item, index) in leaders.length?leaders[selectedLeaders].list:[]"
              :key="index"
              :dataInput="item"
            ></person-profile-3>
          </scrollview>
        </div>
      </div>
      <div style="margin-top: 3.7vh">
        <Subtitle text="关爱预警"></Subtitle>
        <table-2 class="customTable" :columns="warningColumn" :data="warningList"></table-2>
      </div>
    </Coldiv>
  </div>
</template>

<script>
const Coldiv = () => import("./components/coldiv.vue");
const Subtitle = () => import("./components/subtitle.vue");
const ShortButton = () => import("./components/shortButton.vue");
const scrollview = () => import("./components/scrollview.vue");
const personProfile2 = () => import("./components/personProfile2.vue");
const personProfile3 = () => import("./components/personProfile3.vue");
const LongButton = () => import("./components/longButton.vue");
const table2 = () => import("./components/table2.vue");
const activeNumber = () => import("./components/activeNumber.vue");

export default {
  name: "page5",
  components: {
    Coldiv,
    Subtitle,
    ShortButton,
    scrollview,
    personProfile2,
    personProfile3,
    LongButton,
    table2,
    activeNumber
  },
  data() {
    return {
      classify: [],
      householdNumber: 0,
      povertyHouseNumber: 0,
      specialHouseNumber: 0,
      selectedClassify: 0,
      leaders: [],
      selectedLeaders: 0,
      sKey1: 0,
      sKey2: 0,
      warningList: [
        {
          name: "加载中...",
          groupType: "加载中...",
          reportWay: "加载中...",
          reportData: "加载中...",
          color: "#ffd03b"
        }
      ],
      warningColumn: [
        {
          title: "姓名",
          key: "name",
          width: 22.53
        },
        {
          title: "人群类型",
          key: "groupType",
          width: 23.08
        },
        {
          title: "上报渠道",
          key: "reportWay",
          width: 23.08
        },
        {
          title: "上报时间",
          key: "reportDate",
          width: 31.29
        }
      ],
      loveList: [],
      pollingSIV: null,
      povertyHint: {},
      inspection: false
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if(this.$c.inspection){
      this.inspection = true;
    }
    this.$eventBus.$emit("switchPage", 5);
    this.updatePageData();
    this.pollingSIV = setInterval(() => {
      this.updatePageData();
    }, this.$c.pollingInterval);
  },
  methods: {
    //页面初始化
    updatePageData() {
      this.loadClassifyList();
      this.loadLeaderList();
      this.loadTable();
      this.getReturnPovertyHint();
    },
    //加载居民列表并对其进行分类处理
    loadClassifyList() {
      let sl = [
        "高龄",
        "特扶",
        "返贫",
        "辍学",
        "残疾",
        "优抚",
        "失业",
        "留守",
        "独居",
        "低保",
        "问题",
        "涉毒",
        "艾滋",
        "孤儿",
        "监控",
        "困境",
        "退役",
        "特困"
      ];
      this.$api
        .loadClassifyList({
          pageSize: 99999999
        })
        .then(res => {
          let d = res.data.rows;
          let nameTable = ['一栋','二栋','三栋','四栋','五栋','六栋','七栋','八栋','九栋','十栋','十一栋','十二栋','十三栋','十四栋','十五栋','十六栋','十七栋','十八栋','十九栋','二十栋','二十一栋','二十二栋','二十三栋','二十四栋','二十五栋','二十六栋','二十七栋','二十八栋','二十九栋','三十栋','三十一栋','三十二栋','三十三栋','三十四栋','三十五栋','三十六栋'];
          let buildingInfo = [];
          let t = [];
          //初始化返回数组
          for (let k = 0; k < sl.length; k++) {
            let obj = {
              typeName: sl[k],
              list: [],
              total: 0
            };
            t.push(obj);
          }
          for(let i = 0;i < nameTable.length;i++){
            buildingInfo.push({
              buildingNumber: nameTable[i],
              households: [],
              householdNumber: 0,
              povertyHouse: [],
              povertyHouseNumber: 0,
              specialHouse: [],
              specialHouseNumber: 0
            });
          }
          for (let i = 0; i < d.length; i++) {
            let p = d[i];
            let x = nameTable.indexOf(p.building);
            if(x >= 0){
              let b = buildingInfo[x];
              let h = b.households;
              let pvh = b.povertyHouse;
              let sph = b.specialHouse;
              let bb = x+1;
              let grp = p.groupId; 
              let rm = p.roomNumber;
              let hsn = '' + bb + grp + rm;
              if(h.indexOf(hsn) < 0){
                h.push(hsn);
                b.householdNumber++;
              }
              let labels;
              if(p.label && p.label.length){
                labels = JSON.parse(p.label);
              }
              if(labels && labels.length){
                for(let j = 0;j < labels.length;j++){
                  if(labels[j] == '低保'){
                    if(pvh.indexOf(hsn) < 0){
                      pvh.push(hsn);
                      b.povertyHouseNumber++;
                    }
                  }
                  if(labels[j] == '特困'){
                    if(sph.indexOf(hsn) < 0){
                      sph.push(hsn);
                      b.specialHouseNumber++;
                    }
                  }
                }
              }
            }
            let labels;
            if (p.label && p.label.length) {
              labels = JSON.parse(p.label);
            }
            //获取到单个居民的标签数组，若标签存在，则对其进行分类操作，否则跳过
            if (labels && labels.length) {
              for (let j = 0; j < labels.length; j++) {
                let x = sl.indexOf(labels[j]);
                //只有当居民的标签在sl所列情况中才将居民进行分类。若其标签是楼栋长或者网格员等其他标签，则跳过
                //由于数据质量不佳，为避免本身带有高龄标签的情况出现而导致高龄居民重复出现，所以在这一步筛选掉x=0的情况，可筛选掉假高龄居民也可以避免重复
                if (x >= 1) {
                  t[x].list.push(p);
                  t[x].total++;
                }
              }
            }
            //针对高龄的居民，获取其年龄，判断是否属于高龄者并纳入高龄类型，无需根据label来确定
            let { birthday } = p;
            let age = this.$u.getAge(birthday);
            //配置变量在utils/index.js修改
            if (age >= this.$c.highAgeIs) {
              t[0].list.push(p);
              t[0].total++;
            }
          }
          //统计总户数。迎检场景下，直接写死为要求的数值。非迎检版本则统计真实值
          if(this.$c.inspection){
            this.householdNumber = 906;
            this.povertyHouseNumber = 48;
            this.specialHouseNumber = 247;
          }else{
            for(let i = 0;i < nameTable.length;i++){
              this.householdNumber += buildingInfo[i].householdNumber;
              this.povertyHouseNumber += buildingInfo[i].povertyHouseNumber;
              this.specialHouseNumber += buildingInfo[i].specialHouseNumber;
            }
          }
          //根据社区要求，把其中问题、艾滋、涉毒和监控的数据写死
          t[10].total = 14; // 问题
          t[11].total = 70; // 涉毒
          t[12].total = 123; // 艾滋
          t[14].total = 73; // 监控
          this.classify = t;
          this.sKey1++;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //获取社区服务体系列表（或者领导列表）
    loadLeaderList() {
      this.$api
        .loadLeaders({
          pageSize: 9999
        })
        .then(res => {
          let listReturn = [];
          let list = res.data.rows;
          for (let i = 0; i < list.length; i++) {
            let item = {};
            item.typeName = list[i].serviceName;
            let members = [];
            for (let j = 0; j < list[i].members.rows.length; j++) {
              let person = list[i].members.rows[j];
              members.push({
                name: person.memberName,
                position: person.role,
                avatar: person.avatar
              });
            }
            item.list = members;
            item.total = list[i].members.total;
            listReturn.push(item);
          }
          this.leaders = listReturn;
          this.sKey2++;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //获取关爱预警信息（这一部分仍然使用的假数据）
    loadTable() {
      this.$api
        .loadWarningList({
          pageSize: 9999999
        })
        .then(res => {
          let d = res.data;
          let list = d.rows;
          for (let i = 0; i < list.length; i++) {
            if (list[i].reportWay == "手环") {
              //list[i].reportWay = "手环";
              //手环
              list[i].color = "#3dffff";
            } else if (list[i].reportWay == "摄像头") {
              //list[i].reportWay = "摄像头";
              //摄像头
              list[i].color = "#ff3b3b";
            } else {
              //list[i].reportWay = "小程序";
              //小程序
              list[i].color = "#63e7b7";
            }
          }
          this.warningColumn = [
            {
              title: "姓名",
              key: "nameCn",
              width: 22.53
            },
            {
              title: "人群类型",
              key: "status",
              width: 23.08
            },
            {
              title: "上报渠道",
              key: "reportWay",
              width: 23.08
            },
            {
              title: "上报时间",
              key: "reportDate",
              width: 31.29
            }
          ];
          this.warningList = list;
        });
    },
    //scrollview组件debug所用的函数，不要修改
    selectClassify(p) {
      this.selectedClassify = p;
      this.sKey1++;
    },
    //scrollview组件debug所用的函数，不要修改
    selectLeader(p) {
      this.selectedLeaders = p;
      this.sKey2++;
    },
    //返贫提示
    getReturnPovertyHint() {
      /* this.$api.returnPovertyHint().then(res => {
        console.log(res.data.data, "返贫提示");
        this.povertyHint = res.data.data;
        console.log(
          this.povertyHint,
          "-------------------this.povertyHint---------"
        );
      }); */
      
    },
    addCommas(val) {
      var str = val.toString();
      1;
      // 从右向左每三位添加一个逗号
      return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  beforeDestroy() {
    clearInterval(this.pollingSIV);
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/less/index.less";
.page5 {
  height: 100%;
  width: 100%;
  background: none;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  transform: translateY(-1.11vh);
  z-index: 10;

  .customTable {
    width: 90.48%;
    height: 14vh;
    margin-left: 4.76%;
    margin-top: 1.85vh;
  }

  .classifyTagRow {
    width: 92.26%;
    height: 2.78vh;
    margin-left: 3.81%;
    margin-bottom: 0.74vh;
    display: flex;
    justify-content: space-between;
  }
  .shortButton {
    width: 27.97%;
  }
  .classifyList {
    width: 95.24%;
    height: 59.54vh;
    margin-top: 1.85vh;
  }

  .serviceSystemBox {
    width: 90.48%;
    height: 33.89vh;
    margin-left: 4.76%;
    margin-top: 1.85vh;
    display: flex;
    justify-content: space-between;

    .serviceButtonBox {
      width: 48.42%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .longButton {
        width: 100%;
        height: 2.78vh;
      }
    }
    .serviceList {
      width: 51.58%;
      height: 100%;
    }
  }

  .love-box {
    margin-left: 4.76%;
    margin-top: 1.85vh;
    .love-img {
      width: calc(100% - 4.76%);
    }
    .love-content {
      margin-right: 20px;
      display: flex;
      justify-content: space-around;
      .love-content-item {
        .love-content-top {
          margin-top: 0.4vh;
          display: flex;
          align-items: center;
          img {
            width: 1vw;
            margin-right: 0.2vw;
          }
          span {
            font-size: 1.3vh;
            color: rgba(225, 225, 225, 0.8);
          }
        }
      }
      .love-content-number {
        font-family: titleFont;
        font-size: 3.2vh;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
      }
    }
  }
}
</style>
